import * as React from "react"
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import PageDetails from "../../../components/PageDetails";
import groupImg1 from "../../../images/Group 1.png"
import groupImg2 from "../../../images/Group 2.png"
let isLocal = ['dev', 'development'].includes(process.env.NODE_ENV);
let fr_url = (isLocal ? "" : process.env.FR_URL) + "/fr/ressources/";
let en_url = (isLocal ? "" : process.env.EN_URL) + "/en/resources/";
const Resources = () => {

    return (<>
            <Header title={"Resources | Global Affairs Job Connector"}
                    description={"Resources | Global Affairs Job Connector"}
                    lang={"EN"}
                    langLink={fr_url}
                    breadcrumbs={[{text: "Resources for job opportunities at our Canadian offices abroad", url: en_url}]}
                    bodyClasses={"page-en-resources"}/>
            <main property="mainContentOfPage" resource="#wb-main" class="container pb-5" typeof="WebPageElement">
                <h1>Resources for job opportunities at our Canadian offices abroad</h1>
                <p/>
                <a href={"https://www.international.gc.ca/global-affairs-affaires-mondiales/home-accueil.aspx?lang=eng"}>Global Affairs Canada (home page)</a>
                <p/>
                <a href={"https://www.international.gc.ca/world-monde/country-pays/index.aspx?lang=eng"}>Where Canada works abroad</a>
                <p/>
                <a href={"https://www.international.gc.ca/global-affairs-affaires-mondiales/corporate-ministere/contact-contactez/index.aspx?lang=eng"}>Contact Global Affairs Canada</a>
                <p/>
                <a href={"https://www.international.gc.ca/global-affairs-affaires-mondiales/corporate-ministere/contact-contactez/social-media-medias-sociaux.aspx?lang=eng"}>Stay connected through social media and digital tools</a>
                <p/>
                <div className="row wb-eqht wb-init">
                    <div className="col-sm-6">
                        <div className="brdr-rds-0 eqht-trgt"
                             style={{verticalAlign: "top", minHeight: 200, marginTop: 20}}>
                            <img className="img-responsive full-width"
                                 src={groupImg1} alt=""/>
                        </div>
                        <p/>
                    </div>
                    <div className="col-sm-6">
                        <div className="brdr-rds-0 eqht-trgt"
                             style={{verticalAlign: "top", minHeight: 200, marginTop: 20}}>
                            <img className="img-responsive full-width"
                                 src={groupImg2} alt=""/>
                        </div>
                        <p/>
                    </div>
                </div>
                <PageDetails lang={"EN"} dateModified={"2022-01-05"}/>
            </main>
            <Footer lang={"EN"}/>
        </>
    )
}
export default Resources;